import { useField, useFormikContext } from 'formik';
import { useRef } from 'react';
import styled, { css } from 'styled-components';

import { usePermissionAwareDisabled } from '../../../contexts/TeamPermissionContext';
import Icon from '../../base/Icon';
import Text from '../../base/Text';
import DropdownMenu, { DropdownMenuProps } from '../../DropdownMenu';
import { Div, StyledUtilsProps } from '../../helpers/StyledUtils';

interface SelectOption {
  value: string;
  label: string;
  description?: string;
  disabled?: boolean;
}

interface Props extends StyledUtilsProps {
  label?: string;
  name: string;
  options: SelectOption[];
  help?: string;
  type?: string;
  placeholder?: string;
  auto_complete?: string;
  minimal?: boolean;
  required?: boolean;
  block?: boolean;
  search?: boolean;
  disabled?: boolean;
  onChange?: () => void;
  dropdown?: Partial<DropdownMenuProps>;
}

const StyledField = styled(Div)<{ minimal?: boolean; large?: boolean; block?: boolean }>(
  ({ block }) => css`
    width: fit-content;

    ${block &&
    css`
      width: 100%;
    `};

    &:last-of-type {
      margin-bottom: 0;
    }
  `,
);

const StyledSelect = styled(Div)<{ error: boolean; has_value: boolean }>(
  ({ theme, error, has_value }) => css`
    position: relative;
    width: 100%;

    &:focus-within {
      outline: none;
      &::after {
        content: '';
        position: absolute;
        top: -3px;
        left: -3px;
        right: -3px;
        bottom: -3px;
        border: 2px solid ${theme.colors.outline.focus.primary};
        border-radius: ${theme.radius.large};
      }
    }

    select {
      width: 100%;
      cursor: pointer;
      border-radius: ${theme.radius.normal};
      padding: ${theme.spacing(1.25)} ${theme.spacing(3)};
      padding-right: ${({ theme }) => theme.spacing(9)};
      appearance: none;
      background-position: right 16px center;
      background-image: ${`url("data:image/svg+xml,%3Csvg width='8' height='6' viewBox='0 0 8 6' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M3.99656 5.19967C3.91656 5.19967 3.84156 5.18579 3.77156 5.15801C3.70156 5.13023 3.63879 5.08856 3.58323 5.03301L0.422196 1.87197C0.296219 1.746 0.236007 1.60245 0.241563 1.44134C0.247118 1.28023 0.311007 1.13856 0.43323 1.01634C0.555452 0.894119 0.697118 0.833008 0.85823 0.833008C1.01934 0.833008 1.16101 0.894119 1.28323 1.01634L3.9999 3.74967L6.73323 1.01634C6.85545 0.894119 6.99712 0.835786 7.15823 0.841341C7.31934 0.846897 7.46101 0.910786 7.58323 1.03301C7.70545 1.15523 7.76656 1.2969 7.76656 1.45801C7.76656 1.61912 7.70357 1.76235 7.5776 1.88771L4.41656 5.03301C4.35656 5.08856 4.29156 5.13023 4.22156 5.15801C4.15156 5.18579 4.07656 5.19967 3.99656 5.19967Z' fill='%23${
        error
          ? theme.colors.on.neutral.danger.replace('#', '')
          : theme.colors.on.neutral.secondary_neutral.replace('#', '')
      }'/%3E%3C/svg%3E%0A")`};
      background-color: transparent;
      width: 100%;
      line-height: ${({ theme }) => theme.pxToRem(20)};

      position: relative;

      color: ${has_value
        ? theme.colors.on.neutral.primary_neutral
        : theme.colors.on.neutral.secondary_neutral};

      ${error &&
      css`
        color: ${theme.colors.on.neutral.danger}};
        select {
          border-color: ${theme.colors.outline.danger};
          background-color: ${theme.colors.surface.container.danger};
        }

      `}

      &:hover {
        border-color: ${theme.colors.outline.hover.neutral};
      }

      &::-ms-expand {
        display: none;
      }

      &:focus {
        outline: none;
      }

      option {
        display: none;
      }
    }
  `,
);

const SelectInput: React.FC<Props> = ({
  label,
  name,
  auto_complete,
  required,
  help,
  minimal,
  options,
  block = false,
  search = false,
  dropdown = {},
  disabled: _disabled,
  placeholder,
  onChange,
  ...props
}) => {
  const disabled = usePermissionAwareDisabled(_disabled);
  const [field, meta, { setValue, setTouched }] = useField({
    name,
  });

  let error = meta.error;
  const touched = meta.touched;

  // Empty value don't get validated by default by Formik for Select. Workarround..
  if (!error && required && !field.value) {
    error = 'Required';
  }

  const { submitCount } = useFormikContext();
  const has_error = (touched || submitCount > 0) && !!error;
  const select_ref = useRef<HTMLSelectElement>(null);
  return (
    <Div m={{ bottom: 4 }} {...props}>
      <StyledField minimal={minimal} large block={block}>
        {label && (
          <Div flex={{ justify: 'space-between', align: 'center' }}>
            <label htmlFor={name}>
              <Text size="s" subtitle>
                {label}{' '}
                {required && (
                  <Text danger as="span">
                    *
                  </Text>
                )}
              </Text>
            </label>
          </Div>
        )}
        <DropdownMenu
          p={0}
          parent_width
          search={search}
          placement={'bottom'}
          onToggle={(opened) => {
            if (!opened && search) {
              setTouched(true);
            }
          }}
          renderToggle={(opened, toggle) => (
            <StyledSelect error={has_error} has_value={!!field.value}>
              <select
                {...field}
                value={field.value || ''}
                autoComplete={auto_complete}
                required={required}
                disabled={disabled}
                ref={select_ref}
                onBlur={undefined}
                onMouseDown={() => {
                  select_ref.current?.focus();
                  toggle(!opened);
                }}>
                <option value="" disabled>
                  {placeholder || 'Select one...'}
                </option>
                {options.map((option) => (
                  <option key={option.value} disabled={!!option.disabled} value={option.value}>
                    {option.label}
                  </option>
                ))}
              </select>
            </StyledSelect>
          )}
          {...dropdown}
          options={options.map((option) => ({
            label: option.label,
            description: option.description,
            disabled: option.disabled,
            onClick: () => {
              setValue(option.value);
              onChange && onChange();
            },
          }))}
        />
      </StyledField>
      {has_error && (
        <Text m={{ t: 1, b: 0 }} size="s" as="p" danger>
          <Icon icon="info" left={1} />
          {error}
        </Text>
      )}
      {help && !has_error && (
        <Text m={{ t: 1, b: 0 }} size="s" as="p" muted>
          <Icon icon="info" left={1} />
          {help}
        </Text>
      )}
    </Div>
  );
};

export default SelectInput;
